/**
 * Pesos calculados de bolsa para determinados productos por código
 */
export const WeightProduct = [

     //QUITAMOS LA RESTICCION PARA EL TOCINO AHUMADO****
    // {
       // code: 96, weight:
    //},
    

         //HUEVOS TIPO A Y B
         {
            code: 28, weight:0.065
        },

        


    //AGREGAMOS LOS MINIMOS PARA LOS COMBOS
    //*****************

        //COMBO (250gr/250gr)

    {
        code: 'c1', weight:0.500
    },

    {
        code: 'C1', weight:0.500
    },

    {
        code: 'C2', weight:0.500
    },
    {
        code: 'c2', weight:0.500
    },
    {
        code: 'c4', weight:0.500
    },
    {
        code: 'C4', weight:0.500
    },  
            //650GR COMBROS
    {
        code: 'c3', weight:0.650
    },
    {
        code: 'C3', weight:0.650
    },
    {
        code: 'c6', weight:0.650
    },
    {
        code: 'C6', weight:0.650
    },





    //CHICHARRON
     {
        code: 33, weight:0.080
    },
    //MORATADELA DE 450GR ******
    {
        code: 69, weight:0.450 
    },
    //MORTADELA 900GR J KING  *****
    {
        code: 84, weight:0.900 
    },
    //MANTECA
    {
        code: 34, weight:0.680
    },

      //YOGURT
    {
        code: 77, weight:0.520
    },
      //YOGURT DE SABOR
      {
        code: 92, weight:0.520
    },


       //QUESO DE MANO
    {
        code: 8, weight:0.640
    },

         //QUESO GUAYANES
    {
        code: 39, weight:0.640
    },

         //CREMA DE LECHES TINAS 250GR
         {
            code: 21, weight:0.250
        },
        //CREMA DE LECHES TINAS 500GR
        {
            code: 41, weight:0.250
        },
        //CREMA DE LECHES BOLSA 500GR
        {
            code: 64, weight:0.500
        }
];