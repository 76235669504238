//Tipos de salidas 
module.exports = {

    category: [
        'REFRIGERACION',
        'ARTICULO DE OFICINA', 
        'EQUIPO FINANCIERO',
    ],

    type: [
        'ARTICULO',
        'REPUESTO', 
    ],

    condition: [
        'NUEVO',
        'USADO',
        'PARA REPUESTO',
    ],

    status: [
        'ACTIVO',
        'INACTIVO',
        'EN REPARACIÓN',
    ],
}